import React from "react";
import "../../Style/form.css";
import { Button, Form, Input } from "antd";
import Form1 from "./Form1";
import ComponentAnimation from "../ComponentAnimatin/ComponentAnimation";

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 14,
        },
    },
};

function FormContainer(props) {
    return (
        <></>
        // <ComponentAnimation animationType={"fade-in-up"}>
        //     <div className="form-main-container">
        //         <div className="formCardContainer">
        //             {/* <h2>Limited Period Offer !!</h2> */}
        //             <Form1 isModalForm={false} />
        //         </div>
        //     </div>
        // </ComponentAnimation>
    );
}

export default FormContainer;
