import React from "react";
import "../../Style/location.css";
import ayodhyaMap from "../../Assets/goaImges/goa-map.jpeg";
import MainContaonerHeader from "../MainContaonerHeader/MainContaonerHeader";
import { Col, Row } from "antd";

function LocationConnectivety({ locationRef }) {
    return (
        <div className="location-page-container" id="location_and_conectivity" ref={locationRef}>
            <MainContaonerHeader mainTitle={"Location & Connectivity"} subTitle={""} />
            <div className="Location-detail-container">
                <div className="map-image-container">
                    <img src={ayodhyaMap} />
                </div>
                <div className="location-summary-container">
                    <p className="location-summary">
                        <b>
                            INAUGURATION OF
                            <br />7 NEW DEVELOPMENT PROJECTS.
                        </b>
                    </p>
                    <div className="locationCardContainer">
                        <Row>
                            <Col xxl={8} lg={8} md={8} sm={12} xs={24}>
                                <div className="locationInfoCard">
                                    <p>
                                        <h3>2024</h3>
                                    </p>
                                    <p>
                                        Goa;s GDPS for 2023-24 is projected to reach <b>₹1 lac carore</b> with a growth rate of <b>10.3% </b> and a per capita
                                        income of <b>₹6.3 Lac,</b> indicating strong economic resilience and growth.
                                    </p>
                                </div>
                            </Col>

                            <Col xxl={8} lg={8} md={8} sm={12} xs={24}>
                                <div className="locationInfoCard">
                                    <p>
                                        <h3>2026</h3>
                                    </p>
                                    <p>
                                        Goa has 927 MT of iron ore with a revenue potential of <b>₹3 Lac crore</b> at codt of USD 100/tonne. Influx of{" "}
                                        <b>₹1,000 crore</b> is estimated from the mining secter through the auction of 25 mining blocks.
                                    </p>
                                </div>
                            </Col>
                            <Col xxl={8} lg={8} md={8} sm={12} xs={24}>
                                <div className="locationInfoCard">
                                    <p>
                                        <h3>2029</h3>
                                    </p>
                                    <p>
                                        At Invest Goa Summit, the Chief Minister has commited to make Goa the <b>carbon credit capital</b> of India. Influx of{" "}
                                        <b>₹1,000 caror</b> in green mnufacturing is expexted, offering more than <b>10,000 job</b> oppotunities.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LocationConnectivety;
