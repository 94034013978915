import React from "react";
import "../../Style/containerMainHeading.css";

function MainContaonerHeader(props) {
    let { mainTitle, subTitle } = props;
    return (
        <div className="mainHeader-text-container">
            <h3>{mainTitle ? mainTitle : ""}</h3>
            {subTitle ? <h4>{subTitle}</h4> : ""}
        </div>
    );
}

export default MainContaonerHeader;
