import React from "react";
import "../../Style/slidercomponent.css";
import MainContaonerHeader from "../MainContaonerHeader/MainContaonerHeader";
import sliderImg1 from "../../Assets/goaImges/property-view.jpg";
import sliderImg2 from "../../Assets/goaImges/170831133019-panjim-church-goa.jpg";
import sliderImg3 from "../../Assets/goaImges/sunrise.jpg";
import sliderImg4 from "../../Assets/goaImges/land-project.jpeg";
import sliderImg5 from "../../Assets/goaImges/swimingpull.jpeg";
import { Col, Row } from "antd";
import { Carousel } from "antd";
import Swiper from "swiper";
import { SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";

const contentStyle = {
    height: "550px",
    color: "#fff",
    background: "#fff",
    boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
};

function ImageSliderComponent({ amenitiesRef }) {
    var swiperOptions = {
        loop: true,
        autoplay: {
            delay: 1,
            disableOnInteraction: false,
        },
        slidesPerView: "auto",
        speed: 1000,
        grabCursor: true,
        mousewheelControl: true,
        keyboardControl: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    };

    var swiper = new Swiper(".swiper-container", swiperOptions);

    return (
        <div className="amenities-Slider-container" id="amenities" ref={amenitiesRef}>
            <MainContaonerHeader mainTitle={"Amenities"} subTitle={""} />
            <div className="sliderContainer">
                <Carousel autoplay speed={100} effect={"fade"}>
                    <div style={contentStyle}>
                        <div className="CarousalImageContainer">
                            <img src={sliderImg1} />
                        </div>
                    </div>
                    <div style={contentStyle}>
                        <div className="CarousalImageContainer">
                            <img src={sliderImg2} />
                        </div>
                    </div>
                    <div style={contentStyle}>
                        <div className="CarousalImageContainer">
                            <img src={sliderImg3} />
                        </div>
                    </div>
                    <div style={contentStyle}>
                        <div className="CarousalImageContainer">
                            <img src={sliderImg4} />
                        </div>
                    </div>
                    <div style={contentStyle}>
                        <div className="CarousalImageContainer">
                            <img src={sliderImg5} />
                        </div>
                    </div>
                </Carousel>

                {/* <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    freeMode={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[FreeMode, Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>Slide 1</SwiperSlide>
                    <SwiperSlide>Slide 2</SwiperSlide>
                    <SwiperSlide>Slide 3</SwiperSlide>
                    <SwiperSlide>Slide 4</SwiperSlide>
                    <SwiperSlide>Slide 5</SwiperSlide>
                    <SwiperSlide>Slide 6</SwiperSlide>
                    <SwiperSlide>Slide 7</SwiperSlide>
                    <SwiperSlide>Slide 8</SwiperSlide>
                    <SwiperSlide>Slide 9</SwiperSlide>
                </Swiper> */}

                {/* <Swiper pagination={{ dynamicBullets: true }} modules={[Pagination]}>
                    {[0, 6, 12].map((start, slideIndex) => (
                        <SwiperSlide key={slideIndex}>
                            <img src={sliderImg1} />
                        </SwiperSlide>
                    ))}
                </Swiper> */}

                {/* <Row gutter={[24, 0]}>
                    <Col xxl={8} lg={8} md={8} sm={12} xs={24}>
                        <div className="sliderImageContainer">
                            <img src={sliderImg1} />
                        </div>
                    </Col>
                    <Col xxl={8} lg={8} md={8} sm={12} xs={24}>
                        <div className="sliderImageContainer">
                            <img src={sliderImg2} />
                        </div>
                    </Col>
                    <Col xxl={8} lg={8} md={8} sm={12} xs={24}>
                        <div className="sliderImageContainer">
                            <img src={sliderImg3} />
                        </div>
                    </Col>
                </Row> */}
                <div className="amenities-services-container">
                    <Row gutter={[24, 24]} style={{ marginTop: "20px" }}>
                        <Col xxl={8} lg={8} md={8} sm={12} xs={24}>
                            <div className="ServicesCard">
                                <h4>Beach Access</h4>
                                <div className="allFacilites">
                                    <Row>
                                        <Col xl={12} sm={24} xs={12}>
                                            <div>
                                                <p>Seaside</p>
                                                <p>Coastal</p>
                                                <p>Sandy</p>
                                                <p>Waterside</p>
                                                <p>Beachfront</p>
                                            </div>
                                        </Col>
                                        <Col xl={12} sm={24} xs={12}>
                                            <div>
                                                <p>Shoreline</p>
                                                <p>Tidal</p>
                                                <p>Oceanfront</p>
                                                <p>Aquatic</p>
                                                <p>Lagoon</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={8} lg={8} md={8} sm={12} xs={24}>
                            <div className="ServicesCard">
                                <h4>Swimming Pool</h4>
                                <div className="allFacilites">
                                    <Row>
                                        <Col xl={12} sm={24} xs={12}>
                                            <div>
                                                <p>Aquatic</p>
                                                <p>Refreshing</p>
                                                <p>Plunge</p>
                                                <p>Oasis</p>
                                                <p>Leisure</p>
                                            </div>
                                        </Col>
                                        <Col xl={12} sm={24} xs={12}>
                                            <div>
                                                <p>Tranquil</p>
                                                <p>Resort-style</p>
                                                <p>Crystal-clear</p>
                                                <p>Relaxation</p>
                                                <p>Hydrotherapy</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={8} lg={8} md={8} sm={12} xs={24}>
                            <div className="ServicesCard">
                                <h4>Clubhouse</h4>
                                <div className="allFacilites">
                                    <Row>
                                        <Col xl={12} sm={24} xs={12}>
                                            <div>
                                                <p>Social hub</p>
                                                <p>Recreational facility</p>
                                                <p>Lounge</p>
                                                <p>Gathering place</p>
                                                <p>Entertainment venue</p>
                                                <p>Leisure club</p>
                                            </div>
                                        </Col>
                                        <Col xl={12} sm={24} xs={12}>
                                            <div>
                                                <p>Multi-purpose space</p>
                                                <p>Community center</p>
                                                <p>Pavilion</p>
                                                <p>Amenities center</p>
                                                <p>Event space</p>
                                                {/* <br /> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xxl={8} lg={8} md={8} sm={12} xs={24}>
                            <div className="ServicesCard">
                                <h4>Landscaped Gardens</h4>
                                <div className="allFacilites">
                                    <Row>
                                        <Col xl={12} sm={24} xs={12}>
                                            <div>
                                                <p>Verdant</p>
                                                <p>Serene</p>
                                                <p>Manicured</p>
                                                <p>Lush</p>
                                                <p>Zen</p>
                                                {/* <br /> */}
                                            </div>
                                        </Col>
                                        <Col xl={12} sm={24} xs={12}>
                                            <div>
                                                <p>Greenery</p>
                                                <p>Botanical</p>
                                                <p>Flora</p>
                                                <p>Scenic</p>
                                                <p>Foliage</p>
                                                {/* <br /> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xxl={8} lg={8} md={8} sm={12} xs={24}>
                            <div className="ServicesCard">
                                <h4>Security Features</h4>
                                <div className="allFacilites">
                                    <Row>
                                        <Col xl={12} sm={24} xs={12}>
                                            <div>
                                                <p>Safety</p>
                                                <p>Surveillance</p>
                                                <p>Guarded</p>
                                                <p>Secure</p>
                                                <p>Access control</p>
                                                {/* <br /> */}
                                            </div>
                                        </Col>
                                        <Col xl={12} sm={24} xs={12}>
                                            <div>
                                                <p>CCTV</p>
                                                <p>Patrol</p>
                                                <p>Alarm system</p>
                                                <p>Monitoring</p>
                                                <p>Perimeter fencing</p>
                                                {/* <br /> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xxl={8} lg={8} md={8} sm={12} xs={24}>
                            <div className="ServicesCard">
                                <h4>Environmental Sustainability</h4>
                                <div className="allFacilites">
                                    <Row>
                                        <Col xl={12} sm={24} xs={12}>
                                            <div>
                                                <p>Green</p>
                                                <p>Eco-friendly</p>
                                                <p>Renewable</p>
                                                <p>Conservation</p>
                                                <p>Sustainablel</p>
                                                {/* <br /> */}
                                            </div>
                                        </Col>
                                        <Col xl={12} sm={24} xs={12}>
                                            <div>
                                                <p>Energy-efficient</p>
                                                <p>Carbon-neutral</p>
                                                <p>Eco-conscious</p>
                                                <p>Earth-friendly</p>
                                                <p>Low-impact</p>
                                                {/* <br /> */}
                                                {/* <p></p> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default ImageSliderComponent;
