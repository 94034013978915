import React from "react";
import "../../Style/navbar.css";
import { EnvironmentOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function Footer() {
    return (
        <div className="footer-main-container">
            <footer>
                <div className="footer-Content-box">
                    <div className="footerLink-Container">
                        <p>
                            <Link to={"/disclaimer"}>Disclaimer</Link>
                        </p>
                        <p>
                            <Link to={"/privacy-policy"}>Privacy Policy</Link>
                        </p>
                        <p>
                            <Link to={"/terms-conditions"}>Terms & Conditions</Link>
                        </p>
                    </div>
                    <div className="footer-navBtnBox">
                        <p>
                            <a href="whatsapp://send?text=Hey, I am interested in Goa Project&phone=+918460206227">Click Here For Virtual Presentation</a>
                        </p>
                    </div>
                </div>
                {/* <div className="address-text">
                    <p>
                        <EnvironmentOutlined /> 3rd Floor, Lodha Excelus, Appolo Mills Compound, NM Joshi Marg, Mahalakshmi, Mumbai, Maharashtra, 400011.
                    </p>
                </div> */}
                <p className="copyrite-text">
                    Copyright © 2024 newplotlaunching.com | Powered by{" "}
                    <a href="newplotlaunching.com" target="_blank">
                        newplotlaunching.com
                    </a>
                </p>
            </footer>
        </div>
    );
}

export default Footer;
