import '../TermsConditions/TermsConditions.css'


// DEFINE TITLE FUNCTION  AND EXPORT  
export const Title = ({ children}) => {
    // DEFINE RETURN OF TITLE FUNCTION
    return (
        <>
            <div className="heading_title">
                <h3>{children}</h3>
            </div>
        </>
    );
};

// DEFINE AND EXPORT DESCRIPTION FUNCTION
export const Description = ({ children }) => {
    return (
        <div className="description">
            <p>{children}</p>
        </div>
    );
};

// DEFINE AND EXPORT BULLET POINT FUNCTION
export const BulletPoints = ({ children }) => {
    return <li className="bulletPoint">{children}</li>;
};

// DEFINE AND EXPORT SUB TITLE FUNCTION
export const SubTitle = ({ children }) => {
    return (
        <div className="subTitle">
            <h5>{children}</h5>
        </div>
    );
};