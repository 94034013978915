// DEFINE ALL EXTERNAL IMPORT 
import React, { useRef, useState } from 'react'
import { Col, Row } from 'antd';
// DEFINE ALL INTERNAL IMPORT
import Navbar from '../navbar/Navbar';
import Footer from '../Footer/Footer';
import blogImg from '../../Assets/blog2.jpg'
import './Blog.css'
import { Description, SubTitle, Title } from '../Typography/Typography';

// DEFINE MAIN FUNCTION
const BlogComponent = () => {
    // DEFINE ALL STATES
    const [openContactModal, setOpenContactModal] = useState(true);
    const virtualTourRef = useRef(null);
    const whyUsRef = useRef(null);
    const contactRef = useRef(null);
    const aboutRef = useRef(null);
    const locationRef = useRef(null);
    const amenitiesRef = useRef(null);
    const priceRef = useRef(null);
    const scrollToRef = (ref) => {
        if (ref.current) {
            window.scrollTo({
                top: ref.current.offsetTop - 120,
                behavior: "smooth",
            });
        }
    };

    // DEFINE MAIN FUNCTION RETURN 
    return (
        <>
            <Navbar
                scrollToRef={scrollToRef}
                virtualTourRef={virtualTourRef}
                whyUsRef={whyUsRef}
                contactRef={contactRef}
                aboutRef={aboutRef}
                locationRef={locationRef}
                priceRef={priceRef}
                amenitiesRef={amenitiesRef}
            />
            <main className='mainBlog'>
                {/* <div className='blogImage'>

                </div> */}
                <Row justify={"center"} align={"middle"}>
                    <Col xs={22} md={20} lg={20} xxl={20}>
                        <div className='topTitle'>
                            <SubTitle> Unveiling the Charm of Abhinandan Lodha: Your Gateway to a Luxurious Lifestyle in Goa
                            </SubTitle>


                        </div>
                        <Row justify={"center"} style={{ padding: "20px 0" }} align={"middle"} gutter={32} >
                            <Col xs={24} md={12}>
                                <img src={blogImg} height={"100%"} width={"100%"} alt='image'></img>
                            </Col>
                            <Col xs={24} md={12}>
                                <Description>
                                    Are you dreaming of a serene escape nestled in the tropical paradise of Goa? Look no further than Abhinandan Lodha, where luxury meets tranquility in the heart of this coastal haven. Situated amidst lush greenery and just a stone's throw away from the azure waters of the Arabian Sea, Abhinandan Lodha offers an unparalleled living experience that combines modern comfort with the timeless charm of Goa.

                                </Description>
                            </Col>
                        </Row>
                        <SubTitle>Discovering the Allure of Abhinandan Lodha
                        </SubTitle>
                        <Description>Abhinandan Lodha is more than just a residential development; it's a testament to the art of fine living. From the moment you step foot on its grounds, you're greeted by a sense of grandeur and sophistication that permeates every corner of this exclusive enclave.</Description>
                        <SubTitle>A Feast for the Senses: Indulge in the Finest Amenities
                        </SubTitle>
                        <Description>
                            At Abhinandan Lodha, luxury knows no bounds. Whether you're lounging by the poolside, unwinding in the spa, or breaking a sweat in the state-of-the-art fitness center, every moment is an opportunity to pamper yourself.
                        </Description>
                        <SubTitle>Embracing Nature: Your Personal Oasis in Goa</SubTitle>
                        <Description>
                            One of the highlights of Abhinandan Lodha is its lush green surroundings, which provide a tranquil backdrop for your everyday life. Imagine waking up to the gentle rustle of palm trees and the sweet scent of frangipani flowers wafting through the air. With meticulously landscaped gardens and verdant lawns, every inch of Abhinandan Lodha is designed to evoke a sense of harmony with nature.
                        </Description>
                        <SubTitle>A Culinary Journey: Exploring the Flavors of Goa</SubTitle>
                        <Description>
                            No visit to Goa is complete without indulging in its vibrant culinary scene, and Abhinandan Lodha is no exception. From traditional Goan delicacies to international cuisine, the on-site restaurants offer a tantalizing array of options to satisfy every palate.
                        </Description>
                        <SubTitle>Your Gateway to Adventure: Exploring the Wonders of Goa</SubTitle>
                        <Description>
                            Beyond the confines of Abhinandan Lodha lies a world of adventure waiting to be discovered. Whether you're soaking up the sun on pristine beaches, exploring ancient forts and temples, or embarking on a sunset cruise along the river, Goa offers endless opportunities for exploration and discovery.
                        </Description>
                        <SubTitle>Investing in Your Future: Why Abhinandan Lodha Is the Ultimate Choice</SubTitle>
                        <Description>
                            In addition to its unparalleled amenities and prime location, Abhinandan Lodha presents a lucrative investment opportunity for discerning buyers. With Goa's real estate market on the rise, owning a piece of this prestigious development is not just a lifestyle choice but a sound financial decision.
                        </Description>
                        <SubTitle>Final Thoughts: Embrace the Abhinandan Lodha Lifestyle</SubTitle>
                        <Description>
                            In conclusion, Abhinandan Lodha represents the epitome of luxury living in Goa. Whether you're seeking a peaceful retreat, an adventurous getaway, or a wise investment opportunity, Abhinandan Lodha offers it all and more. So why wait? Come experience the magic of Abhinandan Lodha and unlock the door to your dreams in Goa's most coveted address.                        </Description>
                    </Col>
                </Row>
            </main>
            <Footer />
        </>
    )
}


// DEFINE MAIN FUNCTION EXPORTS --------------------------------

export default BlogComponent