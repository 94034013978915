import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Components/navbar/Navbar";
import { FloatButton } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import AboutAbhinandanLodha from "./Components/secondContainer/AboutAbhinandanLodha";
import FormContainer from "./Components/FormContainer/FormContainer";
import LocationConnectivety from "./Components/LocationConectivity/LocationConnectivety";
import ImageSliderComponent from "./Components/ImageSliderComponent/ImageSliderComponent";
import PricingCards from "./Components/PricingComponent/PricingCards";
import Virtualtour from "./Components/virtualTour/Virtualtour";
import Footer from "./Components/Footer/Footer";
import ContactUs from "./Components/ContactUs/ContactUs";
import WhyUs from "./Components/WhyUs/WhyUs";
import Modal from "antd";
import { useEffect, useRef, useState } from "react";
import FormModal from "./Components/FormContainer/FormModal";
import BannerImage from "./Components/BannerImage/BannerImage";

function HomePage() {
    const [openContactModal, setOpenContactModal] = useState(true);
    const virtualTourRef = useRef(null);
    const whyUsRef = useRef(null);
    const contactRef = useRef(null);
    const aboutRef = useRef(null);
    const locationRef = useRef(null);
    const amenitiesRef = useRef(null);
    const priceRef = useRef(null);
    const scrollToRef = (ref) => {
        if (ref.current) {
            window.scrollTo({
                top: ref.current.offsetTop - 120,
                behavior: "smooth",
            });
        }
    };

    return (
        <div className="App">
            <Navbar
                scrollToRef={scrollToRef}
                virtualTourRef={virtualTourRef}
                whyUsRef={whyUsRef}
                contactRef={contactRef}
                aboutRef={aboutRef}
                locationRef={locationRef}
                priceRef={priceRef}
                amenitiesRef={amenitiesRef}
            />
            <BannerImage aboutRef={aboutRef} />
            <AboutAbhinandanLodha  />
            <FormContainer />
            <LocationConnectivety locationRef={locationRef} />
            <ImageSliderComponent amenitiesRef={amenitiesRef} />
            <PricingCards priceRef={priceRef} />
            <Virtualtour virtualTourRef={virtualTourRef} />
            <WhyUs whyUsRef={whyUsRef} />
            <ContactUs contactRef={contactRef} />
            <Footer />
            <FormModal openContactModal={openContactModal} setOpenContactModal={setOpenContactModal} />
            <FloatButton
                className="textfloat-button"
                description="Contact Now"
                shape="square"
                style={{
                    right: 70,
                    width: 100,
                    color: "#6fb62e !important",
                    background: "transparent",
                }}
                href="tel:+918460206227"
            />  
            <FloatButton href="tel:+918460206227" trigger="click" style={{ background: "#396214" }} icon={<PhoneOutlined />} />
        </div>
    );
}

export default HomePage;
