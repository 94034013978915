import React from "react";
import "../../Style/aboutAbhinandan.css";
import MainContaonerHeader from "../../Components/MainContaonerHeader/MainContaonerHeader";
import sideImage1 from "../../Assets/goaImges/GOA-2.jpg";
import ComponentAnimation from "../ComponentAnimatin/ComponentAnimation";

function AboutAbhinandanLodha({ aboutRef }) {
    return (
        <></>
        // <ComponentAnimation animationType="fade-in-right">
        //     <div className="abhinandan-lodha-page-container" id="about" ref={aboutRef}>
        //         <MainContaonerHeader
        //             mainTitle={"We are proud to present CODENAME G.O.A. (Greatest. Opportunity. Arrives.)"}
        //             subTitle={"– A RARE FIND AND GOA’s GREATEST LAUNCH."}
        //         />
        //         <div className="lodha-detail-container">
        //             <div className="about-summary-container">
        //                 <p>130+ acre, 5-star gated development.</p>
        //                 <p>An upcoming 5-star luxury hotel within the development.</p>
        //                 <p>An international lifestyle destination.</p>
        //                 <p>Top-notch leading branded hospitality firm, with F&B offerings.</p>
        //                 <p>Branded club.</p>
        //                 <p>Branded gymnasium.</p>
        //             </div>
        //             <div className="slider-container">
        //                 <div className="imageBorderClip-Path-box">
        //                     <img src={sideImage1} />
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </ComponentAnimation>
    );
}

export default AboutAbhinandanLodha;
