import React from "react";
import "../../Style/navbar.css";
import herobannerDesk from "../../Assets/Desktop-banner-&-Mobile-banner.jpg";
import mobileBaneer from "../../Assets/Desktop banner & Mobile banner-02.jpg";
import { Col, Row } from "antd";
import ComponentAnimation from "../ComponentAnimatin/ComponentAnimation";
function BannerImage({ aboutRef }) {
    return (
        <div className="hero-banner-container">
            <Row>
                <Col xl={24} lg={24} sm={24} xs={0}>
                    <div className="hero-banner-Image" id="about" ref={aboutRef}>
                        <img src={herobannerDesk} />
                    </div>
                </Col>
                <Col xl={0} lg={0} sm={0} xs={24}>
                    <div className="hero-banner-Image">
                        <img src={mobileBaneer} />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default BannerImage;
