import React, { useRef } from "react";
import youtubeicon from "../../Assets/youtube-circle-logo-svgrepo-com.svg";
import "../../Style/virtualtour.css";

function Virtualtour({ virtualTourRef }) {
    return (
        <div className="virtual-main-container" id="virtual_tour" ref={virtualTourRef}>
            <div className="videoCardContainer">
                <div className="yotube_video_btn">
                    <a href="https://youtu.be/eV6hfJD3KK8?si=NMsjXnJLGYEYHKuQ" target="_blank">
                        {/* <img src={youtubeicon} /> */}
                        <svg fill="#ffffff" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M20 5V3.799A.798.798 0 0 0 19.201 3H.801A.8.8 0 0 0 0 3.799V5h2v2H0v2h2v2H0v2h2v2H0v1.199A.8.8 0 0 0 .801 17h18.4a.8.8 0 0 0 .799-.801V15h-2v-2h2v-2h-2V9h2V7h-2V5h2zM8 13V7l5 3-5 3z"></path>
                            </g>
                        </svg>
                    </a>
                </div>
                <h2>Virtual Tour</h2>
                <p>
                    Click Here for Virtual Tour of{" "}
                    <b>
                        <i>"The House of Abhinandan Lodha"</i>
                    </b>
                </p>
            </div>
        </div>
    );
}

export default Virtualtour;
