import "../../Style/form.css";
import { Button, Form, Input, message } from "antd";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = ({ prop }) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [city, setCity] = useState('')

    console.log("handleModalCancel", prop?.isModalForm);

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("sdfsdfgk");

        emailjs
            .sendForm("service_8hbd9fi", "template_9be8o8e", form.current, {
                publicKey: "lBvd4vSaCEzGJiyuC",
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    message.success("Your Message is Successfully Sent");

                    if (prop?.isModalForm == true) {
                        prop?.handleModalCancel();
                    }
                    setCity('');
                    setName('');
                    setPhone('');
                    setEmail('')
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    message.error("Failed to Send Message");
                    if (prop?.isModalForm == true) {
                        prop?.handleModalCancel();
                    }
                }
            );
    };

    return (
        <form ref={form} onSubmit={sendEmail}>
            <div className="form-item">
                <label htmlFor="name">Your Name</label>
                <input value={name} onChange={(e) => setName(e.target.value)} type="text" id="name" name="name" placeholder="Enter your name" required />
            </div>
            <div className="form-item">
                <label htmlFor="email">Your Email</label>
                <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="email" name="email" placeholder="Enter email address" required />
            </div>
            <div className="form-item">
                <label htmlFor="phone">Mobile Number</label>
                <input value={phone} onChange={(e) => setPhone(e.target.value)} type="tel" id="phone" name="phone" placeholder="Enter mobile number" required />
            </div>
            <div className="form-item">
                <label htmlFor="city">Your City Name</label>
                <input type="text" value={city} onChange={(e) => setCity(e.target.value)} id="city" name="city" placeholder="Enter your city name" required />
            </div>
            <div className="button-container">
                <button type="submit" className="submit-btn">
                    Submit
                </button>
            </div>
        </form>
    );
};

export default ContactForm;
