import React, { useEffect, useState, useRef } from "react";
import "../../Style/navbar.css";
import heroImg from "../../Assets/ayodhya-temple.jpg";
import logo from "../../Assets/the-imperial-goa-lodha-abhinandan-land-plots-hoabl-projects-house-logo.png";
import jayShreeRamLogo from "../../Assets/jay-shree-ram-logo.png";
import { Button, Col, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function Navbar(props) {
    let { scrollToRef, virtualTourRef, aboutRef, locationRef, amenitiesRef, priceRef, whyUsRef, contactRef } = props;
    const [scrollPosition, setScrollPosition] = useState(0);
    const firstPageRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState("");
    const getFirstPageHeight = () => {
        if (firstPageRef.current) {
            return firstPageRef.current.clientHeight;
        }
        return 0;
    };

    // Use this function to dynamically get the height of the first page
    const firstPageHeight = getFirstPageHeight();
    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const onChnageMenu = (val) => {
        onClose();
        scrollToRef(val);
        setActiveMenu(val);
    };
    return (
        <div>
            <div className={"mainNavContainer pageScrolled"}>
                <header>
                    <div className="header_navbar_container">
                        <div className="left_header">
                            <div className="logo_container">
                                <img src={logo} alt="logo" />
                            </div>
                            {/* {screenWidth >= 992 ? ( */}
                            {/* <> */}
                            <div className="menu_Item_Container">
                                <nav>
                                    <div className={activeMenu == "aboutRef" ? "active menuItem" : "menuItem"}>
                                        <a onClick={() => onChnageMenu(aboutRef)}>About</a>
                                    </div>
                                    <div className={activeMenu == "locationRef" ? "active menuItem" : "menuItem"}>
                                        <a onClick={() => onChnageMenu(locationRef)}>Location & Connectivity</a>
                                    </div>

                                    <div className={activeMenu == "amenitiesRef" ? "active menuItem" : "menuItem"}>
                                        <a onClick={() => onChnageMenu(amenitiesRef)}>Amenities</a>
                                    </div>
                                    <div className={activeMenu == "priceRef" ? "active menuItem" : "menuItem"}>
                                        <a onClick={() => onChnageMenu(priceRef)}>Price</a>
                                    </div>

                                    <div className={activeMenu == "virtualTourRef" ? "active menuItem" : "menuItem"}>
                                        <a onClick={() => onChnageMenu(virtualTourRef)}>Virtual Tour</a>
                                    </div>
                                    <div className={activeMenu == "whyUsRef" ? "active menuItem" : "menuItem"}>
                                        <a onClick={() => onChnageMenu(whyUsRef)}>Why Us?</a>
                                    </div>
                                    <div className="menuItem">
                                        <a
                                            // href="https://drive.google.com/file/d/14MgpgaQnCk_ddosEEAnpo_lp7eeRCtmy/view"
                                            onClick={() => onChnageMenu(contactRef)}
                                        >
                                            Download Brochure
                                        </a>
                                    </div>
                                    <div className={activeMenu == "contactRef" ? "active menuItem" : "menuItem"}>
                                        <a onClick={() => onChnageMenu(contactRef)}>Contact Us</a>
                                    </div>
                                    <div className={activeMenu == "contactRef" ? "active menuItem" : "menuItem"}>
                                        <Link to={"/blog"}>Blog</Link>
                                    </div>
                                </nav>
                            </div>
                            {/* </> */}
                            {/* ) : (
                                <>
                                    <div style={{ cursor: "pointer" }} onClick={showDrawer}>
                                        <MenuOutlined />
                                    </div>
                                </>
                            )} */}
                        </div>
                        <div className="navBtnBox">
                            <p>
                                <a href="whatsapp://send?text=Hey, I am interested in Goa Project&phone=+918460206227">Virtual Presentation</a>
                            </p>
                        </div>
                    </div>
                </header>
                <div className="emptyHedader"></div>
                {/* <div className="TitalContentContainer">
                    <div className="contentCard">
                        <h2>Goa's Greatest Land Launch</h2>
                        <h1>Coming Soon</h1>
                        <h3>Pre-Launch offer for a Limited Period Only</h3>
                    </div>
                </div> */}
            </div>
            {/* <Drawer title="Menu" onClose={onClose} open={open}>
                <div className="Drawer-menu_Container">
                    <nav>
                        <div className="drawer-menuItem">
                            <a onClick={() => onChnageMenu(aboutRef)}>About</a>
                        </div>
                        <div className="drawer-menuItem">
                            <a onClick={() => onChnageMenu(locationRef)}>Location & Connectivity</a>
                        </div>

                        <div className="drawer-menuItem">
                            <a onClick={() => onChnageMenu(amenitiesRef)}>Amenities</a>
                        </div>
                        <div className="drawer-menuItem">
                            <a onClick={() => onChnageMenu(priceRef)}>Price</a>
                        </div>

                        <div className="drawer-menuItem">
                            <a onClick={() => onChnageMenu(virtualTourRef)}>Virtual Tour</a>
                        </div>
                        <div className="drawer-menuItem">
                            <a onClick={() => onChnageMenu(whyUsRef)}>Why Us?</a>
                        </div>
                        <div className="drawer-menuItem">
                            <a onClick={() => onChnageMenu(contactRef)}>Download Brochure</a>
                        </div>
                        <div className="drawer-menuItem">
                            <a onClick={() => onChnageMenu(contactRef)}>Contact Us</a>
                        </div>
                    </nav>
                </div>
            </Drawer> */}
        </div>
    );
}

export default Navbar;
