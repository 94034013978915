import React, { useRef, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import BlogComponent from '../Components/Blog/Blog'
import HomePage from '../HomePage'
import Disclaimer from './../Components/Disclaimer/Disclaimer';
import PrivacyPolicy from './../Components/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './../Components/TermsConditions/TermsConditions';
import ContactUs from '../Components/ContactUs/ContactUs';

import Navbar from '../Components/navbar/Navbar';
import Footer from '../Components/Footer/Footer';

const RouteList = () => {
    const [openContactModal, setOpenContactModal] = useState(true);
    const virtualTourRef = useRef(null);
    const whyUsRef = useRef(null);
    const contactRef = useRef(null);
    const aboutRef = useRef(null);
    const locationRef = useRef(null);
    const amenitiesRef = useRef(null);
    const priceRef = useRef(null);
    const scrollToRef = (ref) => {
        if (ref.current) {
            window.scrollTo({
                top: ref.current.offsetTop - 120,
                behavior: "smooth",
            });
        }
    };
    return (
        <BrowserRouter>
            {/* <Navbar
                scrollToRef={scrollToRef}
                virtualTourRef={virtualTourRef}
                whyUsRef={whyUsRef}
                contactRef={contactRef}
                aboutRef={aboutRef}
                locationRef={locationRef}
                priceRef={priceRef}
                amenitiesRef={amenitiesRef}
            /> */}
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/blog' element={<BlogComponent />} />
                <Route path='/disclaimer' element={<Disclaimer />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/terms-conditions' element={<TermsConditions />} />
            </Routes>
            {/* <ContactUs contactRef={contactRef} /> */}
            {/* <Footer /> */}
        </BrowserRouter>
    )
}

export default RouteList