// DEFINE ALL EXTERNAL IMPORT 
import React from "react";
import { Col, Row } from "antd";


// DEFINE ALL INTERNAL IMPORT
import "../TermsConditions/TermsConditions.css";
import { Description, Title } from "../Typography/Typography";

// DEFINE MAIN FUNCTION
const Disclaimer = () => {
  //  DEFINE MAIN FUNCTION RETURN 
  return (
    <div className="termsMainContainer">
      <Row justify={"center"} align={"middle"}>
        <Col xs={22} md={18} lg={18} xxl={18}>
          <Title>Disclaimers for THE HOUSE OF ABHINANDAN LODHA</Title>
          <Description>
            If you require any more information or have any questions about our
            site's disclaimer, please feel free to contact us by email at
            rio678406@gmail.com. Our Disclaimer was generated with the help of
            the Free Disclaimer Generator.
          </Description>
          <Description>
            All the information on this website - newplotlaunching.com - is
            published in good faith and for general information purpose only.
            THE HOUSE OF ABHINANDAN LODHA does not make any warranties about the
            completeness, reliability and accuracy of this information. Any
            action you take upon the information you find on this website (THE
            HOUSE OF ABHINANDAN LODHA ), is strictly at your own risk. THE HOUSE
            OF ABHINANDAN LODHA will not be liable for any losses and/or damages
            in connection with the use of our website.
          </Description>
          <Description>
            From our website, you can visit other websites by following
            hyperlinks to such external sites. While we strive to provide only
            quality links to useful and ethical websites, we have no control
            over the content and nature of these sites. These links to other
            websites do not imply a recommendation for all the content found on
            these sites. Site owners and content may change without notice and
            may occur before we have the opportunity to remove a link which may
            have gone 'bad'.
          </Description>
          <Description>
            Please be also aware that when you leave our website, other sites
            may have different privacy policies and terms which are beyond our
            control. Please be sure to check the Privacy Policies of these sites
            as well as their "Terms of Service" before engaging in any business
            or uploading any information.
          </Description>

          <Title>Consent</Title>
          <Description>
            By using our website, you hereby consent to our disclaimer and agree
            to its terms.
          </Description>
          <Title>Update</Title>
          <Description>
            Should we update, amend or make any changes to this document, those
            changes will be prominently posted here.
          </Description>
        </Col>
      </Row>
    </div>
  );
};
// DEFINE MAIN FUNCTION EXPORT
export default Disclaimer;
