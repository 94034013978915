import React from "react";
import FormContainer from "./FormContainer";
import { Modal } from "antd";
import Form1 from "./Form1";
import "../../Style/form.css";

function FormModal(props) {
    let { setOpenContactModal, openContactModal } = props;

    const showModal = () => {
        setOpenContactModal(true);
    };
    const handleOk = () => {
        setOpenContactModal(false);
    };
    const handleModalCancel = () => {
        setOpenContactModal(false);
    };

    return (
        <Modal className="contactForm-modal" footer={null} open={openContactModal} onOk={handleOk} onCancel={handleModalCancel}>
            <Form1 isModalForm={true} handleModalCancel={handleModalCancel} setOpenContactModal={setOpenContactModal} />
        </Modal>
    );
}

export default FormModal;
