import React from "react";
import "../../Style/priceCard.css";
import { Col, Row } from "antd";
import MainContaonerHeader from "../MainContaonerHeader/MainContaonerHeader";
import { Badge, Card, Space } from "antd";

function PricingCards({ priceRef }) {
    return (
        <div className="pricecard-main-Container" id="price" ref={priceRef}>
            <MainContaonerHeader mainTitle={"Pricing"} subTitle={""} />
            <div className="pricecard-container">
                <Row justify="center">
                    <Col xl={10} lg={10} md={12} sm={12} xs={24}>
                        {/* <div className="blog_post">
                            <div className="card_container_copy">
                                <h3>Pre-Launch Price</h3>
                                <h1>
                                    1250 <small>Sq.Ft.</small>
                                </h1>
                                <p>Stamp Duty Waiver</p>
                                <p>Registraion Waiver</p>
                                <div className="pricdbtnbox">
                                    <a className="btn_primary" href="#" target="_blank">
                                        1.80 Cr All In
                                    </a>
                                </div>
                            </div>
                        </div> */}

                        <div className="pricing-column">
                            <div className="pricing-price-row">
                                <div className="pricing-price-wrapper">
                                    <div className="pricing-price">
                                        <div className="pricing-cost">₹99 Lacs</div>
                                        <div className="time">(all-in)</div>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-row-title">
                                <div className="pricing_row_title">1500 sq.ft.</div>
                            </div>
                            <figure className="pricing-row">
                                Invest in Goa's greatest land opportunity
                                <br />
                                LAUNCG: 130+ acres, branded club.
                            </figure>
                            {/* <figure className="pricing-row">
                                <span>Drop out ramen hustle</span>
                            </figure>
                            <figure className="pricing-row strike">Crush revenue traction</figure>
                            <figure className="pricing-row strike">Crush revenue traction</figure>
                            <figure className="pricing-row strike">User base minimum viable</figure>
                            <figure className="pricing-row strike">Lorem ipsum dolor</figure> */}
                            <div className="pricing-footer">
                                <div className="gem-button-container gem-button-position-center">
                                    <a href="#" className="gem-button gem-green">
                                        Click Here
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    {/* <Col xl={6} lg={6} md={12} sm={12} xs={24}>
                        <div className="blog_post">
                            <Badge.Ribbon text="Sold Out" color="rgb(248 207 41)">
                                <div className="card_container_copy">
                                    <h3>Pre-Launch Price</h3>
                                    <h1>
                                        1500 <small>Sq.Ft.</small>
                                    </h1>
                                    <p>Stamp Duty Waiver</p>
                                    <p>Registraion Waiver</p>
                                    <div className="pricdbtnbox">
                                        <a className="btn_primary" href="#" target="_blank">
                                            Click Here
                                        </a>
                                    </div>
                                </div>
                            </Badge.Ribbon>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={24}>
                        <div className="blog_post">
                            <div className="card_container_copy">
                                <h3>Pre-Launch Price</h3>
                                <h1>
                                    1750 <small>Sq.Ft.</small>
                                </h1>
                                <p>Stamp Duty Waiver</p>
                                <p>Registraion Waiver</p>
                                <div className="pricdbtnbox">
                                    <a className="btn_primary" href="#" target="_blank">
                                        Click Here
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={24}>
                        <div className="blog_post">
                            <Badge.Ribbon text="Sold Out" color="rgb(248 207 41)">
                                <div className="card_container_copy">
                                    <h3>Pre-Launch Price</h3>
                                    <h1>
                                        2000 <small>Sq.Ft.</small>
                                    </h1>
                                    <p>Stamp Duty Waiver</p>
                                    <p>Registraion Waiver</p>
                                    <div className="pricdbtnbox">
                                        <a className="btn_primary" href="#" target="_blank">
                                            Click Here
                                        </a>
                                    </div>
                                </div>
                            </Badge.Ribbon>
                        </div>
                    </Col> */}
                </Row>
            </div>
        </div>
    );
}

export default PricingCards;
