import React from "react";
import MainContaonerHeader from "../MainContaonerHeader/MainContaonerHeader";
import { Col, Row } from "antd";
import "../../Style/contactUs.css";

function WhyUs({ whyUsRef }) {
    return (
        <div className="whyUs-container" id="why_us" ref={whyUsRef}>
            <MainContaonerHeader mainTitle={"Why House of Abhinandan Lodha?"} subTitle={""} />
            <div className="contact-infoContainer">
                <Row gutter={[24, 24]}>
                    <Col xl={24} md={24} sm={24} xs={24}>
                        <div className="whyUsCard">
                            <h4>The Promise of Security.</h4>
                            <p>Owning a Land with House of Abhinandan Lodha is now safe and secure investment</p>
                        </div>
                    </Col>
                    <Col xl={24} md={24} sm={24} xs={24}>
                        <div className="whyUsCard">
                            <h4>The Promise of Liquidity.</h4>
                            <p>We Provide Resell & Rental Assistance to our valued customer</p>
                        </div>
                    </Col>
                    <Col xl={24} md={24} sm={24} xs={24}>
                        <div className="whyUsCard">
                            <h4>The Promise of Transparency.</h4>
                            <p>
                                These days, dealing with land is difficult. At HOABL, we offer complete transparency by uploading all clear land titles to RERA
                                in accordance with regulatory requirements.
                            </p>
                        </div>
                    </Col>
                    <Col xl={24} md={24} sm={24} xs={24}>
                        <div className="whyUsCard">
                            <h4>The Promise of Wealth.</h4>
                            <p>
                                It’s never been easier to build money for future generations. And now the generations that come before you are protected by the
                                assurance of Land.
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default WhyUs;
