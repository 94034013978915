import "../../Style/form.css";
import { Button, Form, Input, message } from "antd";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import ContactForm from "./ContactForm";
import { CloseOutlined } from "@ant-design/icons";

function Form1(props) {
    let { isModalForm, handleModalCancel } = props;
    console.log("props: " + props.handleModalCancel)
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("sdfsdfgk");

        emailjs
            .sendForm("service_8hbd9fi", "template_9be8o8e", form.current, {
                publicKey: "lBvd4vSaCEzGJiyuC",
            })
            .then(
                () => {
                    console.log("SUCCESS!");
                    message.success("Your Message is Successfully Sent");
                },
                (error) => {
                    console.log("FAILED...", error.text);
                    message.error("Failed to Send Message");
                }
            );
    };
    return (
        <div className="formContainer">
            {/* {isModalForm == true && <h2>Limited Period Offer !!</h2>} */}
            <div className="closeIconContainer" onClick={handleModalCancel}>
                <CloseOutlined />
            </div>
            <h2>Limited Period Offer !!</h2>
            <ContactForm prop={props} />
            {/* <form ref={form} onSubmit={sendEmail}>
                <div className="form-item">
                    <label htmlFor="name">Your Name</label>
                    <input type="text" id="name" name="name" placeholder="Enter your name" required />
                </div>
                <div className="form-item">
                    <label htmlFor="email">Your Email</label>
                    <input type="email" id="email" name="email" placeholder="Enter email address" required />
                </div>
                <div className="form-item">
                    <label htmlFor="phone">Mobile Number</label>
                    <input type="tel" id="phone" name="phone" placeholder="Enter mobile number" required />
                </div>
                <div className="form-item">
                    <label htmlFor="city">Your City Name</label>
                    <input type="text" id="city" name="city" placeholder="Enter your city name" required />
                </div>
                <div className="form-item">

                    <button type="submit" className="submit-btn">Submit</button>

                </div>
            </form> */}
        </div>
    );
}

export default Form1;
