import React from "react";
import "../../Style/contactUs.css";
import MainContaonerHeader from "../MainContaonerHeader/MainContaonerHeader";
import { Button, Form, Input } from "antd";
import Form1 from "../FormContainer/Form1";
import ContactForm from "../FormContainer/ContactForm";

function ContactUs({ contactRef }) {
    return (
        <div className="contactus-main-container" id="contact" ref={contactRef}>
            <MainContaonerHeader mainTitle={"Contact Us"} subTitle={""} />
            <div className="contact-infoContainer">
                <div className="MapContainer">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d984956.6954043872!2d73.34712881413624!3d15.35008443126377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfba106336b741%3A0xeaf887ff62f34092!2sGoa!5e0!3m2!1sen!2sin!4v1713706698457!5m2!1sen!2sin"
                        width="500"
                        height="550"
                        style={{ border: "1px solid greay", borderRadius: "8px" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
                <div className="contactus-form-container">
                    <h3>
                        Enter your details Below to get Best Price for
                        <br />{" "}
                        <b>
                            <u>Plots in Goa</u>
                        </b>
                    </h3>
                    <p className="warning-texts">(Limited Unit Left, Take Action Now)</p>
                    {/* <Form1/> */}
                    <ContactForm />
                    {/* <Form
                        layout="vertical"
                        variant="outlined"
                        style={{
                            maxWidth: 600,
                        }}
                    >
                        <Form.Item
                            label="Your Name"
                            name="your_name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your name!",
                                },
                            ]}
                        >
                            <Input size="large" placeholder="Enter your name" />
                        </Form.Item>
                        <Form.Item
                            label="Your Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter email!",
                                },
                            ]}
                        >
                            <Input type="email" size="large" placeholder="Enter email address" />
                        </Form.Item>
                        <Form.Item
                            label="Mobile Number"
                            name="mobile"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter mobile number!",
                                },
                            ]}
                        >
                            <Input type="tel" size="large" placeholder="Enter mobile number" />
                        </Form.Item>
                        <Form.Item
                            label="Your City Name"
                            name="city"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your city name!",
                                },
                            ]}
                        >
                            <Input size="large" placeholder="Enter your city name" />
                        </Form.Item>

                        <div className="formBtnBox">
                            <p>
                                <a>Submit</a>
                            </p>
                        </div>
                    </Form> */}

                    <div className="more-contact-info">
                        <p>
                            For More Information Get in touch with an expert.
                            <br />
                            <b>Contact: +91 84602 06227</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
